const { countryCodes } = require('../..//i18n/enums/countryCodes');
const POST_HOG_KEY = {
  [countryCodes.PT]: 'phc_NaRjsU7cAlARLkMXz1wm7sjfjV0jXBox33vhfYu2J88',
};

const EXTRA_GUARANTEE_PRODUCT_TYPE_ID = 'eb977bb5-4b02-4f68-bb4d-a66bccd69f56';

const ENABLE_WEBSOCKET = {
  [countryCodes.SK]: false,
  [countryCodes.IE]: false,
  [countryCodes.DE]: false,
  [countryCodes.PT]: false,
  [countryCodes.IT]: false,
  [countryCodes.FR]: false,
  [countryCodes.HU]: false,
  [countryCodes.CZ]: false,
  [countryCodes.AT]: false,
  [countryCodes.NO]: false,
  [countryCodes.NL]: false,
  [countryCodes.CH]: false,
  [countryCodes.BE]: false,
  [countryCodes.DK]: false,
  [countryCodes.SE]: false,
  [countryCodes.ES]: false,
  [countryCodes.GB]: false,
  [countryCodes.FI]: false,
  [countryCodes.PL]: false,
  [countryCodes.RO]: false,
  [countryCodes.LT]: false,
  [countryCodes.SI]: false,
  [countryCodes.BG]: false,
  [countryCodes.GR]: false,
  [countryCodes.HR]: false,
};

module.exports = {
  POST_HOG_KEY,
  ENABLE_WEBSOCKET,
  EXTRA_GUARANTEE_PRODUCT_TYPE_ID,
};
