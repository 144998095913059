import type { Cart } from '@vsf-enterprise/commercetools-types';
import { nextTick, useRouter } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import {
  useStoreVsfExtended,
  useComplementaryProducts,
  useUiState,
  useExtraGuarantee,
  useRouteExtended,
  useCartSidebarStore,
  useStock,
  useBundleBuilderEvents,
  useLineItems,
} from '~/composables';
import { DEBOUNCE_TIMES } from '~/constants/debounceTimes';
import { ROUTES } from '~/constants/routes';

export const useCartSidebars = () => {
  const { isComplementarySidebarEnabled, isExtraGuaranteeEnabled } = useStoreVsfExtended();
  const { search, complementaryProducts } = useComplementaryProducts();
  const { loadStock } = useStock('cart');
  const { skuHasAddedExtraGuarantee, cartItemExtraGuaranteeExcluded } = useExtraGuarantee();
  const {
    isComplementarySidebarOpen,
    isExtraGuaranteeOpen,
    toggleExtraGuarantee,
    toggleComplementarySidebar,
  } = useUiState();
  const { toggleCartSidebar } = useCartSidebarStore();
  const { callViewEvent } = useBundleBuilderEvents();
  const { setIsGuaranteeActionBeingClicked, isGuaranteeActionBeingClicked } = useLineItems();

  const { getAdjustedSlug } = useRouteExtended();
  const router = useRouter();

  const isSafariMobile = () => window?.navigator?.userAgent.match(/(iPod|iPhone|iPad)/) &&
  window?.navigator?.userAgent.match(/AppleWebKit/);

  const handleSafariDelay = (callback: () => void, delay: number) => {
    nextTick(() => {
      setTimeout(callback, delay);
    });
  };

  const openCartSidebars = ({ product, sku, isWithExtraGuarantee }:
     { product?: ProductVariant; sku: string; isWithExtraGuarantee: boolean | undefined}) => {
    if (
      isExtraGuaranteeEnabled.value &&
      !isWithExtraGuarantee &&
      !skuHasAddedExtraGuarantee(product?.sku) &&
      !cartItemExtraGuaranteeExcluded(sku)
    ) {
      handleExtraGuaranteeLogic(sku);
    } else {
      openComplementarySidebar(sku);
    }
  };

  const handleSidebarLogicWithDelayCheck = (actionToPerform: () => void) => {
    if (!actionToPerform) {
      throw new Error('No action provided at handleSidebarLogicWithDelayCheck');
    }
    if (isSafariMobile()) {
      handleSafariDelay(actionToPerform, DEBOUNCE_TIMES.SAFARI_CART_DELAY);
    } else {
      actionToPerform();
    }
  };

  const openComplementarySidebar = async (sku: string) => {
    if (isComplementarySidebarOpen.value) {
      toggleComplementarySidebar();
      handleSidebarLogicWithDelayCheck(toggleCartSidebar);
      return;
    }
    if (!isComplementarySidebarEnabled.value) {
      toggleCartSidebar();
      return;
    }
    await search(sku);
    if (complementaryProducts.value?.length) {
      toggleComplementarySidebar();
      callViewEvent(sku);
    } else {
      toggleCartSidebar();
    }
  };

  const closeComplementarySidebar = (shouldOpenCartSidebar?: boolean) => {
    if (!isComplementarySidebarOpen.value) {
      return;
    }
    toggleComplementarySidebar();

    if (shouldOpenCartSidebar) {
      handleSidebarLogicWithDelayCheck(toggleCartSidebar);
    }
  };

  const handleExtraGuaranteeLogic = (sku: string) => {
    toggleExtraGuarantee();
    if (!isExtraGuaranteeOpen.value) {
      const actionToPerform = isComplementarySidebarEnabled.value && !isGuaranteeActionBeingClicked.value
        ? () => openComplementarySidebar(sku)
        : toggleCartSidebar;
      handleSidebarLogicWithDelayCheck(actionToPerform);
      setIsGuaranteeActionBeingClicked(true);
    }
  };

  const handleGoToCheckout = () => {
    router.push(getAdjustedSlug(ROUTES.CHECKOUT_ADDRESSES));
  };

  const toggleCartSidebarWithDelay = (cart: Maybe<Ref<Cart>> = undefined) => {
    if (cart?.value?.lineItems?.length) {
      loadStock({ items: cart.value.lineItems });
    }
    handleSidebarLogicWithDelayCheck(toggleCartSidebar);
  };

  return {
    handleGoToCheckout,
    openComplementarySidebar,
    closeComplementarySidebar,
    handleExtraGuaranteeLogic,
    openCartSidebars,
    toggleCartSidebarWithDelay,
    toggleCartSidebar,
  };
};
