const { countryCodes } = require('../../i18n/enums/countryCodes');
const { languageCodes } = require('../../i18n');
const {
  DIGITS_ONLY,
  DIGITS_AND_LETTERS,
  DIGITS_LETTERS_AND_SPECIAL_CHARS,
} = require('../../constants/regex');

// the price is regarding the local currency of the country e.g EUR, GBP, CHF etc
const cashOnDeliveryPriceLimit = 1500;
const cashOnDeliveryItemLimit = 1;
const cashOnDeliveryItemHidden = 0;

const CASH_ON_DELIVERY_PRICE_LIMIT = {
  [countryCodes.SK]: 2500,
  [countryCodes.IE]: cashOnDeliveryPriceLimit,
  [countryCodes.DE]: 1500,
  [countryCodes.PT]: 1000,
  [countryCodes.IT]: 999.99,
  [countryCodes.FR]: 1000,
  [countryCodes.HU]: 499999,
  [countryCodes.CZ]: 60000,
  [countryCodes.AT]: 1500,
  [countryCodes.NO]: cashOnDeliveryPriceLimit,
  [countryCodes.NL]: cashOnDeliveryPriceLimit,
  [countryCodes.CH]: 3500,
  [countryCodes.BE]: 3000,
  [countryCodes.DK]: 20000,
  [countryCodes.SE]: 3000,
  [countryCodes.ES]: 1000,
  [countryCodes.GB]: 2500,
  [countryCodes.FI]: cashOnDeliveryPriceLimit,
  [countryCodes.PL]: 6000,
  [countryCodes.RO]: 10000,
  [countryCodes.LT]: 4500,
  [countryCodes.SI]: 4500,
  [countryCodes.BG]: 8900,
  [countryCodes.GR]: 499,
  [countryCodes.HR]: 4500,
};

const CASH_ON_DELIVERY_ITEM_LIMIT = {
  [countryCodes.SK]: cashOnDeliveryItemLimit,
  [countryCodes.IE]: cashOnDeliveryItemHidden,
  [countryCodes.DE]: cashOnDeliveryItemHidden,
  [countryCodes.PT]: cashOnDeliveryItemHidden,
  [countryCodes.IT]: cashOnDeliveryItemLimit,
  [countryCodes.FR]: cashOnDeliveryItemHidden,
  [countryCodes.HU]: cashOnDeliveryItemLimit,
  [countryCodes.CZ]: cashOnDeliveryItemLimit,
  [countryCodes.AT]: cashOnDeliveryItemHidden,
  [countryCodes.NO]: cashOnDeliveryItemHidden,
  [countryCodes.NL]: cashOnDeliveryItemHidden,
  [countryCodes.CH]: cashOnDeliveryItemHidden,
  [countryCodes.BE]: cashOnDeliveryItemHidden,
  [countryCodes.DK]: cashOnDeliveryItemHidden,
  [countryCodes.SE]: cashOnDeliveryItemHidden,
  [countryCodes.ES]: cashOnDeliveryItemHidden,
  [countryCodes.GB]: cashOnDeliveryItemHidden,
  [countryCodes.FI]: cashOnDeliveryItemHidden,
  [countryCodes.PL]: cashOnDeliveryItemLimit,
  [countryCodes.RO]: cashOnDeliveryItemLimit,
  [countryCodes.LT]: cashOnDeliveryItemHidden,
  [countryCodes.SI]: cashOnDeliveryItemLimit,
  [countryCodes.BG]: cashOnDeliveryItemHidden,
  [countryCodes.GR]: cashOnDeliveryItemLimit,
  [countryCodes.HR]: cashOnDeliveryItemLimit,
};

const BANK_PAYMENT_ENABLED = {
  [countryCodes.SK]: true,
  [countryCodes.IE]: false,
  [countryCodes.DE]: true,
  [countryCodes.PT]: true,
  [countryCodes.IT]: true,
  [countryCodes.FR]: true,
  [countryCodes.HU]: true,
  [countryCodes.CZ]: true,
  [countryCodes.AT]: true,
  [countryCodes.NO]: true,
  [countryCodes.NL]: false,
  [countryCodes.CH]: true,
  [countryCodes.BE]: true,
  [countryCodes.DK]: true,
  [countryCodes.SE]: true,
  [countryCodes.ES]: true,
  [countryCodes.GB]: false,
  [countryCodes.FI]: true,
  [countryCodes.PL]: true,
  [countryCodes.RO]: true,
  [countryCodes.LT]: true,
  [countryCodes.SI]: true,
  [countryCodes.BG]: false,
  [countryCodes.GR]: false,
  [countryCodes.HR]: false,
};

const CASH_ON_DELIVERY_FOR_FREIGHT = {
  [countryCodes.SK]: true,
  [countryCodes.IE]: false,
  [countryCodes.DE]: false,
  [countryCodes.PT]: false,
  [countryCodes.IT]: false,
  [countryCodes.FR]: false,
  [countryCodes.HU]: true,
  [countryCodes.CZ]: true,
  [countryCodes.AT]: false,
  [countryCodes.NO]: false,
  [countryCodes.NL]: false,
  [countryCodes.CH]: false,
  [countryCodes.BE]: false,
  [countryCodes.DK]: false,
  [countryCodes.SE]: false,
  [countryCodes.ES]: false,
  [countryCodes.GB]: false,
  [countryCodes.FI]: false,
  [countryCodes.PL]: true,
  [countryCodes.RO]: false,
  [countryCodes.LT]: false,
  [countryCodes.SI]: false,
  [countryCodes.BG]: false,
  [countryCodes.GR]: false,
  [countryCodes.HR]: false,
};

const NOT_SUPPORTED = false;
/**
 * @type {Object.<string, boolean>}
 * Available countries:
 * https://developers.google.com/maps/documentation/address-validation/coverage?hl=pl
 */
const GOOGLE_ADDRESS_VALIDATION = {
  [countryCodes.SK]: false,
  [countryCodes.IE]: false,
  [countryCodes.DE]: false,
  [countryCodes.PT]: NOT_SUPPORTED,
  [countryCodes.IT]: false,
  [countryCodes.FR]: false,
  [countryCodes.HU]: false,
  [countryCodes.CZ]: false,
  [countryCodes.AT]: false,
  [countryCodes.NO]: false,
  [countryCodes.NL]: false,
  [countryCodes.CH]: false,
  [countryCodes.BE]: false,
  [countryCodes.DK]: false,
  [countryCodes.SE]: false,
  [countryCodes.ES]: false,
  [countryCodes.GB]: false,
  [countryCodes.FI]: false,
  [countryCodes.PL]: false,
  [countryCodes.RO]: NOT_SUPPORTED,
  [countryCodes.LT]: false,
  [countryCodes.SI]: NOT_SUPPORTED,
  [countryCodes.BG]: NOT_SUPPORTED,
  [countryCodes.GR]: NOT_SUPPORTED,
  [countryCodes.HR]: NOT_SUPPORTED,
};

/**
 * @type {Object.<string, boolean>}
 */
const MANUAL_TRANSLATION_AVAILABLE = {
  [countryCodes.SK]: false,
  [countryCodes.IE]: true,
  [countryCodes.DE]: true,
  [countryCodes.PT]: false,
  [countryCodes.IT]: false,
  [countryCodes.FR]: false,
  [countryCodes.HU]: false,
  [countryCodes.CZ]: false,
  [countryCodes.AT]: true,
  [countryCodes.NO]: false,
  [countryCodes.NL]: false,
  [countryCodes.CH]: false,
  [countryCodes.BE]: false,
  [countryCodes.DK]: false,
  [countryCodes.SE]: false,
  [countryCodes.ES]: false,
  [countryCodes.GB]: true,
  [countryCodes.FI]: false,
  [countryCodes.PL]: true,
  [countryCodes.RO]: false,
  [countryCodes.LT]: false,
  [countryCodes.SI]: false,
  [countryCodes.BG]: false,
  [countryCodes.GR]: false,
  [countryCodes.HR]: false,
};

const CURRENCY_SHIPPING_FEE_THRESHOLDS = {
  TEMP_FRANCE: {
    thresholdForFreeShipping: 59,
    parcelFee: 6.95,
    freightFee: 39.95,
  },
  TEMP_HUNGARY: {
    thresholdForFreeShipping: 22099,
    parcelFee: 1481,
    freightFee: 7481,
  },
  EUR: {
    thresholdForFreeShipping: 59,
    parcelFee: 1.95,
    freightFee: 9.95,
  },
  GBP: {
    thresholdForFreeShipping: 52,
    parcelFee: 1.95,
    freightFee: 9.95,
  },
  DKK: {
    thresholdForFreeShipping: 439,
    parcelFee: 15,
    freightFee: 74,
  },
  SEK: {
    thresholdForFreeShipping: 659,
    parcelFee: 22,
    freightFee: 111,
  },
  NOK: {
    thresholdForFreeShipping: 689,
    parcelFee: 23,
    freightFee: 116,
  },
  CHF: {
    thresholdForFreeShipping: 59,
    parcelFee: 1.95,
    freightFee: 9.95,
  },
  CZK: {
    thresholdForFreeShipping: 1399,
    parcelFee: 46,
    freightFee: 235,
  },
  PLN: {
    thresholdForFreeShipping: 269,
    parcelFee: 9,
    freightFee: 45,
  },
  HUF: {
    thresholdForFreeShipping: 22099,
    parcelFee: 730,
    freightFee: 3731,
  },
  RON: {
    thresholdForFreeShipping: 289,
    parcelFee: 10,
    freightFee: 49,
  },
  LEV: {
    thresholdForFreeShipping: 115,
    parcelFee: 3.95,
    freightFee: 19.95,
  },
};

/**
 * @type {import('~/types/product/ShippingFeesObject').ShippingFeeThresholds}
 */
const SHIPPING_FEE_THRESHOLDS = {
  [countryCodes.SK]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.IE]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.DE]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.PT]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.IT]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.FR]: CURRENCY_SHIPPING_FEE_THRESHOLDS.TEMP_FRANCE,
  [countryCodes.HU]: CURRENCY_SHIPPING_FEE_THRESHOLDS.TEMP_HUNGARY,
  [countryCodes.CZ]: CURRENCY_SHIPPING_FEE_THRESHOLDS.CZK,
  [countryCodes.AT]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.NO]: CURRENCY_SHIPPING_FEE_THRESHOLDS.NOK,
  [countryCodes.NL]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.CH]: CURRENCY_SHIPPING_FEE_THRESHOLDS.CHF,
  [countryCodes.BE]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.DK]: CURRENCY_SHIPPING_FEE_THRESHOLDS.DKK,
  [countryCodes.SE]: CURRENCY_SHIPPING_FEE_THRESHOLDS.SEK,
  [countryCodes.ES]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.GB]: CURRENCY_SHIPPING_FEE_THRESHOLDS.GBP,
  [countryCodes.FI]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.PL]: CURRENCY_SHIPPING_FEE_THRESHOLDS.PLN,
  [countryCodes.RO]: CURRENCY_SHIPPING_FEE_THRESHOLDS.RON,
  [countryCodes.LT]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.SI]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.BG]: CURRENCY_SHIPPING_FEE_THRESHOLDS.LEV,
  [countryCodes.GR]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
  [countryCodes.HR]: CURRENCY_SHIPPING_FEE_THRESHOLDS.EUR,
};

const CURRENCY_LOW_STOCK_BOUNDARIES = {
  EUR: {
    highBoundary: 1000,
    lowBoundary: 250,
  },
  GBP: {
    highBoundary: 870,
    lowBoundary: 217.5,
  },
  DKK: {
    highBoundary: 7450,
    lowBoundary: 1862.5,
  },
  SEK: {
    highBoundary: 11200,
    lowBoundary: 2800,
  },
  NOK: {
    highBoundary: 11700,
    lowBoundary: 2925,
  },
  CHF: {
    highBoundary: 980,
    lowBoundary: 245,
  },
  CZK: {
    highBoundary: 23600,
    lowBoundary: 5900,
  },
  PLN: {
    highBoundary: 4550,
    lowBoundary: 1137.5,
  },
  HUF: {
    highBoundary: 375000,
    lowBoundary: 93750,
  },
  RON: {
    highBoundary: 4940,
    lowBoundary: 1235,
  },
  LEV: {
    highBoundary: 1950,
    lowBoundary: 487.5,
  },
};

/**
 * @type {import('~/types/product/LowStockBoundaries').LowStockBoundaries}
 */
const LOW_STOCK_BOUNDARIES = {
  [countryCodes.SK]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.IE]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.DE]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.PT]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.IT]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.FR]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.HU]: CURRENCY_LOW_STOCK_BOUNDARIES.HUF,
  [countryCodes.CZ]: CURRENCY_LOW_STOCK_BOUNDARIES.CZK,
  [countryCodes.AT]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.NO]: CURRENCY_LOW_STOCK_BOUNDARIES.NOK,
  [countryCodes.NL]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.CH]: CURRENCY_LOW_STOCK_BOUNDARIES.CHF,
  [countryCodes.BE]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.DK]: CURRENCY_LOW_STOCK_BOUNDARIES.DKK,
  [countryCodes.SE]: CURRENCY_LOW_STOCK_BOUNDARIES.SEK,
  [countryCodes.ES]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.GB]: CURRENCY_LOW_STOCK_BOUNDARIES.GBP,
  [countryCodes.FI]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.PL]: CURRENCY_LOW_STOCK_BOUNDARIES.PLN,
  [countryCodes.RO]: CURRENCY_LOW_STOCK_BOUNDARIES.RON,
  [countryCodes.LT]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.SI]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.BG]: CURRENCY_LOW_STOCK_BOUNDARIES.LEV,
  [countryCodes.GR]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
  [countryCodes.HR]: CURRENCY_LOW_STOCK_BOUNDARIES.EUR,
};

const TRUSTPILOT_SUPPORTED_LANGUAGES = [
  languageCodes.de,
  languageCodes.en,
  languageCodes.es,
  languageCodes.fi,
  languageCodes.fr,
  languageCodes.it,
  languageCodes.nl,
  languageCodes.pt,
  languageCodes.pl,
  languageCodes.nb,
  languageCodes.da,
  languageCodes.sv,
];

const GOOGLE_AUTOCOMPLETE_CITY_FIELDS_TO_USE = {
  [countryCodes.SK]: ['postal_town', 'sublocality_level_1', 'administrative_area_level_2'],
  [countryCodes.CZ]: ['postal_town', 'sublocality_level_1', 'administrative_area_level_2'],
  defaultFields: ['postal_town', 'locality', 'administrative_area_level_1'],
};

/**
 * @type {import('~/types/config/VatFormatConfig').VatFormatConfig}
 */
const VAT_FORMAT = {
  [countryCodes.PL]: {
    rule: DIGITS_ONLY,
    placeholder: '1234567890',
    prefix: 'PL',
  },
  [countryCodes.AT]: {
    rule: DIGITS_AND_LETTERS,
    placeholder: 'U12345678',
    prefix: 'AT',
  },
  [countryCodes.BE]: {
    rule: DIGITS_ONLY,
    placeholder: '1234567890',
    prefix: 'BE',
  },
  [countryCodes.SK]: {
    rule: DIGITS_ONLY,
    placeholder: '1234567890',
    prefix: 'SK',
  },
  [countryCodes.IE]: {
    rule: DIGITS_AND_LETTERS,
    placeholder: '1234567XA',
    prefix: 'IE',
  },
  [countryCodes.DE]: {
    rule: DIGITS_ONLY,
    placeholder: '123456789',
    prefix: 'DE',
  },
  [countryCodes.PT]: {
    rule: DIGITS_ONLY,
    placeholder: '123456789',
    prefix: 'PT',
  },
  [countryCodes.IT]: {
    rule: DIGITS_ONLY,
    placeholder: '12345678901',
    prefix: 'IT',
  },
  [countryCodes.FR]: {
    rule: DIGITS_AND_LETTERS,
    placeholder: 'XX123456789',
    prefix: 'FR',
  },
  [countryCodes.HU]: {
    rule: DIGITS_ONLY,
    placeholder: '12345678',
    prefix: 'HU',
    additionalVatNumberProcessing: (vatNumber) => {
      return vatNumber.substring(0, 8);
    },
  },
  [countryCodes.CZ]: {
    rule: DIGITS_ONLY,
    placeholder: '1234567890',
    prefix: 'CZ',
  },
  [countryCodes.NO]: {
    rule: DIGITS_AND_LETTERS,
    placeholder: '123456789MVA',
    prefix: 'NO',
  },
  [countryCodes.NL]: {
    rule: DIGITS_AND_LETTERS,
    placeholder: '123456789BXX',
    prefix: 'NL',
  },
  [countryCodes.CH]: {
    rule: DIGITS_LETTERS_AND_SPECIAL_CHARS,
    placeholder: '123.456.789XXXX',
    prefix: 'CHE',
  },
  [countryCodes.DK]: {
    rule: DIGITS_ONLY,
    placeholder: '12345678',
    prefix: 'DK',
  },
  [countryCodes.SE]: {
    rule: DIGITS_ONLY,
    placeholder: '123456789012',
    prefix: 'SE',
  },
  [countryCodes.ES]: {
    rule: DIGITS_AND_LETTERS,
    placeholder: 'X1234567X',
    prefix: 'ES',
  },
  [countryCodes.GB]: {
    rule: DIGITS_LETTERS_AND_SPECIAL_CHARS,
    placeholder: '123456789,XX123',
    prefix: 'GB',
  },
  [countryCodes.FI]: {
    rule: DIGITS_ONLY,
    placeholder: '12345678',
    prefix: 'FI',
  },
  [countryCodes.RO]: {
    rule: DIGITS_ONLY,
    placeholder: '1234567890',
    prefix: 'RO',
  },
  [countryCodes.LT]: {
    rule: DIGITS_ONLY,
    placeholder: '123456789012',
    prefix: 'LT',
  },
  [countryCodes.SI]: {
    rule: DIGITS_ONLY,
    placeholder: '12345678',
    prefix: 'SI',
  },
  [countryCodes.BG]: {
    rule: DIGITS_ONLY,
    placeholder: '1234567890',
    prefix: 'BG',
  },
  [countryCodes.GR]: {
    rule: DIGITS_ONLY,
    placeholder: '123456789',
    prefix: 'EL',
  },
  [countryCodes.HR]: {
    rule: DIGITS_ONLY,
    placeholder: '12345678901',
    prefix: 'HR',
  },
};

const POSTAL_CODE_FORMAT = {
  [countryCodes.AT]: /^[1-9]{1}\d{3}$/,
  [countryCodes.BE]: /^[1-9]{1}\d{3}$/,
  [countryCodes.BG]: /^\d{4}$/,
  [countryCodes.CH]: /^\d{4}$/,
  [countryCodes.CZ]: /^\d{3}\s?\d{2}$/,
  [countryCodes.DE]: /^\d{5}$/,
  [countryCodes.DK]: /^\d{4}$/,
  [countryCodes.ES]: /^\d{5}$/,
  [countryCodes.FI]: /^\d{5}$/,
  [countryCodes.FR]: /^\d{5}$/,
  [countryCodes.GB]: /^(GIR ?0AA|[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2})$/,
  [countryCodes.GR]: /^\d{3}\s?\d{2}$/,
  [countryCodes.HR]: /^\d{5}$/,
  [countryCodes.HU]: /^[1-9]{1}\d{3}$/,
  [countryCodes.IE]: /^(([ac-fhknprtv-yAC-FHKNPRTV-Y]\d{2}|D6W|d6w|D6w|d6W)\s?([0-9ac-fhknprtv-yAC-FHKNPRTV-Y]{4})?)$/,
  [countryCodes.IT]: /^\d{5}$/,
  [countryCodes.LT]: /^\d{5}$/,
  [countryCodes.NL]: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/,
  [countryCodes.NO]: /^\d{4}$/,
  [countryCodes.PL]: /^\d{2}-\d{3}$/,
  [countryCodes.PT]: /^\d{4}-\d{3}$/,
  [countryCodes.RO]: /^\d{6}$/,
  [countryCodes.SE]: /^\d{3}\s?\d{2}$/,
  [countryCodes.SI]: /^\d{4}$/,
  [countryCodes.SK]: /^\d{3}\s?\d{2}$/,
};

const STREET_NUMBER_VALIDATION_DISABLED = {
  [countryCodes.AT]: true,
  [countryCodes.BE]: true,
  [countryCodes.BG]: true,
  [countryCodes.CH]: true,
  [countryCodes.CZ]: true,
  [countryCodes.DE]: true,
  [countryCodes.DK]: true,
  [countryCodes.ES]: true,
  [countryCodes.FI]: true,
  [countryCodes.FR]: true,
  [countryCodes.GB]: true,
  [countryCodes.GR]: true,
  [countryCodes.HR]: true,
  [countryCodes.HU]: true,
  [countryCodes.IE]: true,
  [countryCodes.IT]: true,
  [countryCodes.LT]: true,
  [countryCodes.NL]: true,
  [countryCodes.NO]: true,
  [countryCodes.PL]: false,
  [countryCodes.PT]: true,
  [countryCodes.RO]: false,
  [countryCodes.SE]: true,
  [countryCodes.SI]: true,
  [countryCodes.SK]: true,
};

const MERCHANT_ID = {
  [countryCodes.FR]: 131727769,
  [countryCodes.AT]: 133098273,
  [countryCodes.HU]: 132913488,
  [countryCodes.GB]: 133098834,
  [countryCodes.BE]: 133099047,
  [countryCodes.CH]: 133099011,
  [countryCodes.DK]: 133099140,
  [countryCodes.NO]: 133099242,
  [countryCodes.ES]: 133099185,
  [countryCodes.DE]: 133109434,
  [countryCodes.PT]: 133099281,
  [countryCodes.SE]: 133109491,
  [countryCodes.CZ]: 133109779,
  [countryCodes.NL]: 133111811,
  [countryCodes.IE]: 133112174,
  [countryCodes.PL]: 138563743,
  [countryCodes.FI]: 140415001,
  [countryCodes.SK]: 631950721,
  [countryCodes.GR]: 748405187,
  [countryCodes.SI]: 748412289,
  [countryCodes.LT]: 748423270,
  [countryCodes.BG]: 748436977,
  [countryCodes.IT]: 133098738,
  [countryCodes.RO]: 748202494,
};

module.exports = {
  CASH_ON_DELIVERY_PRICE_LIMIT,
  CASH_ON_DELIVERY_ITEM_LIMIT,
  CASH_ON_DELIVERY_FOR_FREIGHT,
  GOOGLE_ADDRESS_VALIDATION,
  SHIPPING_FEE_THRESHOLDS,
  LOW_STOCK_BOUNDARIES,
  TRUSTPILOT_SUPPORTED_LANGUAGES,
  GOOGLE_AUTOCOMPLETE_CITY_FIELDS_TO_USE,
  VAT_FORMAT,
  POSTAL_CODE_FORMAT,
  STREET_NUMBER_VALIDATION_DISABLED,
  MANUAL_TRANSLATION_AVAILABLE,
  BANK_PAYMENT_ENABLED,
  MERCHANT_ID,
};
