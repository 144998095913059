export default (_context, inject) => {
  if (!window.gapi) {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js?onload=renderOptIn';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  inject('renderGCRSurvey', (options) => {
    if (process.client) {
      if (window.gapi && window.gapi.surveyoptin) {
        window.gapi.surveyoptin.render(options);
      } else {
        window.renderOptIn = function () {
          window.gapi.load('surveyoptin', function () {
            window.gapi.surveyoptin.render(options);
          });
        };
      }
    }
  });
};
