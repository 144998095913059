import { useProduct } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ProductsExtraGuaranteeMutations,
} from '~/types/vuex/ProductsExtraGuarantee';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { ProductVariantGql } from '~/types/product/ProductVariantGql';
import { useI18n } from '~/composables/useI18n';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { usePrefix } from '~/composables';
import extendProducts from '~/helpers/product/extendProducts';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { EXTRA_GUARANTEE_PRODUCT_TYPE_ID } from '~/config';

export const useProductsExtraGuarantee = () => {
  const store = defineModule<typeof MODULE_NAMES.EXTRA_GUARANTEES_MODULE_NAME,
  ProductsExtraGuaranteeMutations,
  {},
  {}>(MODULE_NAMES.EXTRA_GUARANTEES_MODULE_NAME);

  const productsExtraGuarantee = computed(() => store.state.productsExtraGuarantee);

  const load = async ():Promise<void> => {
    if (productsExtraGuarantee.value) {
      return;
    }
    const {
      products: extraGuaranteeProducts,
      search: searchExtraGuaranteeProduct,
    } = useProduct<ProductVariantGql>('extra-guarantee-product');
    const { languagePrefix } = usePrefix();

    const { languageAndCountry, countryCode } = useI18n();
    const searchParams = {
      customFilters: `productType(id="${EXTRA_GUARANTEE_PRODUCT_TYPE_ID}")`,
    };
    const productSearchParams = getProductSearchParams(
      searchParams,
      {
        languageAndCountry: languageAndCountry.value,
        languagePrefix,
        includeUnsellableProducts: true,
      },
    );
    await searchExtraGuaranteeProduct({
      ...productSearchParams,
      customQuery: CUSTOM_QUERIES.PRODUCT_SEARCH.extraGuaranteeQuery,
    });

    const products = extraGuaranteeProducts.value?.data?.products?.results || [];
    const extendedProducts = extendProducts(
      products,
      languageAndCountry.value,
      countryCode.value,
      { isPublished: true },
    );
    const filteredProducts = extendedProducts.filter(product => {
      const enabledOnMarket = product.attributesRaw
        ?.find(attr => attr.name === 'enabledOnMarket' && attr.value[languageAndCountry.value] === 'true');
      return enabledOnMarket?.value && Object.keys(enabledOnMarket.value).length > 0;
    });

    store.mutations.setProducts(filteredProducts);
  };

  return {
    productsExtraGuarantee,
    ...store.actions,
    ...store.mutations,
    ...store.getters,
    load,
  };
};
