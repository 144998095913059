const { countryCodes } = require('../..//i18n/enums/countryCodes');

const ENABLE_WEBSOCKET = {
  [countryCodes.SK]: true,
  [countryCodes.IE]: true,
  [countryCodes.DE]: true,
  [countryCodes.PT]: true,
  [countryCodes.IT]: true,
  [countryCodes.FR]: true,
  [countryCodes.HU]: true,
  [countryCodes.CZ]: true,
  [countryCodes.AT]: true,
  [countryCodes.NO]: true,
  [countryCodes.NL]: true,
  [countryCodes.CH]: true,
  [countryCodes.BE]: true,
  [countryCodes.DK]: true,
  [countryCodes.SE]: true,
  [countryCodes.ES]: true,
  [countryCodes.GB]: true,
  [countryCodes.FI]: true,
  [countryCodes.PL]: true,
  [countryCodes.RO]: true,
  [countryCodes.LT]: true,
  [countryCodes.SI]: true,
  [countryCodes.BG]: true,
  [countryCodes.GR]: true,
  [countryCodes.HR]: true,
};

module.exports = {
  POST_HOG_KEY: {
  },
  ENABLE_WEBSOCKET,
  EXTRA_GUARANTEE_PRODUCT_TYPE_ID: 'f7f185e9-d136-45fe-8d28-94a917463903',
};
